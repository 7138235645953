<!--<div class="authentication-main">
  <ng-template #content let-modal>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'FORGOT_PASSWORD'">Forgot Password</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'RESET_PASSWORD'">Reset Password</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'USER_REGISTRATION_OTP'">User Registration OTP</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalService.dismissAll('')"
        >
          ×
        </button>
      </div>
      <div class="modal-body" ngbAutoFocus>
        <div class="row" *ngIf="modalType === 'USER_REGISTRATION_OTP'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <form class="form" novalidate [formGroup]="registrationOtpForm">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <fieldset class="form-group">
                    <input class="form-control" type="number" formControlName="otp"
                           placeholder="Enter OTP">
                    <small *ngIf="submittedOtp && registrationOtpForm.get('otp').hasError('required')"
                           class="text-danger">
                      Field is required.
                    </small>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'FORGOT_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="enrollmentVerifyForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <fieldset class="form-group">
                        <label>Enrollment No.</label>
                        <input class="form-control" type="text" formControlName="enrollment_no" placeholder="Enter Enrollment No.">
                        <small *ngIf="submittedEnrollmentNoVerify && enrollmentVerifyForm.get('enrollment_no').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'RESET_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="updatePasswordForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Verification Code Sent to</label>
                        <input class="form-control" formControlName="contact"/>
                        <small *ngIf="submitted && updatePasswordForm.get('contact').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Password
                          <i (click)="inputTypePassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypePassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypePassword" formControlName="password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Confirm Password
                          <i (click)="inputTypeConfirmPassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypeConfirmPassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypeConfirmPassword" formControlName="confirm_password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('confirm_password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Verification Code</label>
                        <input class="form-control" formControlName="otp" type="number"/>
                        <small *ngIf="submitted && updatePasswordForm.get('otp').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1" *ngIf="modalType === 'USER_REGISTRATION_OTP'">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
            <small>Resend OTP in - </small>
            <small class="text-danger mr-1"
                   *ngIf="timerSixtySeconds > 0">{{timerSixtySeconds}} sec.</small>
            <small class="text-primary cursor-pointer"
                   *ngIf="timerSixtySeconds === 0" (click)="resendOTP()">Resend OTP ?
            </small>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="submitOtp()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'USER_REGISTRATION_OTP'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Submit
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="verifyEnrollmentNo()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'FORGOT_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Verify
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="resetPassword()"
                    [disabled]="submittingPassword" *ngIf="modalType === 'RESET_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingPassword"></i>Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="row">
    <div class="col-md-12">
      <div class="auth-innerright">
        <div class="authentication-box">
          <div class="mt-4">
            <div class="card-header" style="background: transparent; border-bottom: none ; margin-bottom: 20px">
              <div class="main-header-left text-center">
                <div class="logo-wrapper"><a href="javascript:void(0)" routerLink='/'>
                  <img src="assets/images/logo/logo2.png" alt="University Management System" style="height: 70px"></a>
                </div>
              </div>
            </div>
            <div class="card-body px-0">
              <div class="cont text-center b-light" [ngClass]="{'s&#45;&#45;signup': signup}">
                <div>
                  <form class="theme-form" [formGroup]="loginForm">
                    <h4>Sign In</h4>
                    <h6>Sign In to your account and start exploring</h6>
                    <div class="form-group text-left mt-30px">
                      <label class="col-form-label pt-0">Username</label>
                      <input class="form-control border-radius-0" type="text" placeholder="Enrollment No. / Email" formControlName="username"/>
                    </div>
                    <div class="form-group text-left">
                      <label class="col-form-label">Password</label>
                      <input class="form-control border-radius-0" type="password" placeholder="*******" formControlName="password"/>
                      <small class="text-primary">Use your Contact No. or DOB (in YYYY-MM-DD) format as password</small>
                    </div>
                    <div class="checkbox p-0 text-left">
                      <input id="checkbox1" type="checkbox">
                      <label for="checkbox1" class="f_12">
                        <small>Remember me</small>
                      </label>
                    </div>
                    <div class="form-group text-left text-info mt-55px">
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          &lt;!&ndash;<small class="col-form-label f_12 cursor-pointer">Forgot Password ?</small>&ndash;&gt;
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          &lt;!&ndash;<small class="col-form-label f_12 cursor-pointer">Forgot Username ?</small>&ndash;&gt;
                          <small class="col-form-label f_12 cursor-pointer" (click)="openForgotPasswordModal(content)">Forgot Password ?</small>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-row mt-3 mb-0">
                      <button class="btn btn-primary btn-block" type="submit" (click)="signIn()">Sign In</button>
                    </div>
                    <div class="social mt-3 d-block d-md-none height-150px">
                    </div>
                  </form>
                </div>
                <div class="sub-cont">
                  <div class="img">
                    <div class="img__text m&#45;&#45;up">
                      <h2>New Student ?</h2>
                      <p>Register yourself and discover great amount of new opportunities !</p>
                    </div>
                    <div class="img__text m&#45;&#45;in">
                      <h2>Already Registered ?</h2>
                      <p>Sign In to your account and start exploring !</p>
                    </div>
                    <div class="img__btn" (click)=toggle()>
                      <span class="m&#45;&#45;up">Register</span>
                      <span class="m&#45;&#45;in">Sign in</span>
                    </div>
                  </div>
                  <div>
                    <form class="theme-form text-left" [formGroup]="registrationForm">
                      <h4 class="text-center">Register Now</h4>
                      <h6 class="text-center">Enter your details and choose course</h6>
                      <div class="form-row mt-20px">
                        <div class="col-md-12">
                          <div class="form-group">
                            <input class="form-control border-radius-0" type="text" placeholder="Full Name" formControlName="name"/>
                            <small *ngIf="submitted && registrationForm.get('name').hasError('required')"
                                   class="text-danger">
                              Field is required.
                            </small>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <input class="form-control border-radius-0" type="text" placeholder="Email" formControlName="email"/>
                            <small *ngIf="submitted && registrationForm.get('email').hasError('required')"
                                   class="text-danger">
                              Field is required.
                            </small>
                            <small *ngIf="submitted && registrationForm && registrationForm.controls && registrationForm.controls['email'] && registrationForm.controls['email'].errors && registrationForm.controls['email'].errors.pattern"
                                   class="text-danger">Invalid email format</small>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <input class="form-control border-radius-0" type="number" placeholder="Contact" formControlName="contact"/>
                            <small *ngIf="submitted && registrationForm.get('contact').hasError('required')"
                                   class="text-danger">
                              Field is required.
                            </small>
                            <small *ngIf="submitted && registrationForm && registrationForm.controls && registrationForm.controls['contact'] && registrationForm.controls['contact'].errors && registrationForm.controls['contact'].errors.pattern"
                                   class="text-danger">Invalid contact format</small>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <select class="form-control border-radius-0" formControlName="course_id">
                          <option value="">Select Course</option>
                          <option [value]="course.id" *ngFor="let course of courses">{{course?.name}}</option>
                        </select>
                        <small *ngIf="submitted && registrationForm.get('course_id').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                      <div class="checkbox p-0 text-left">
                        <input id="checkbox2" type="checkbox" [(ngModel)]="agreeTermsCondtions" [ngModelOptions]="{standalone: true}">
                        <label for="checkbox2" class="f_12">
                          <small>I agree to terms & conditions</small>
                        </label>
                      </div>
                      &lt;!&ndash;<div class="form-row mt-20px">
                        <div class="col-sm-12 text-center">
                          <small class="col-form-label f_12 cursor-pointer text-info">By Clicking Sign Up, you agree to Terms & Privacy Policy</small>
                        </div>
                      </div>&ndash;&gt;
                      <div class="form-row mt-20px">
                        <div class="col-sm-12">
                          <button class="btn btn-primary btn-block" type="submit" (click)="register()">Register</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->

<!--new Form-->
<!--<section class="" style="background-color: #eee;" style=" height: calc(100vh);">
  <div class="container h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-lg-12 col-xl-11">
        <div class="card text-black" style="border-radius: 25px; border: none;">
          <div class="card-body p-md-5">
            <div class="row justify-content-center">
              <div class="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">

                <p class="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Sign up</p>

                <form class="mx-1 mx-md-4">

                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-user fa-lg me-3 fa-fw"></i>
                    <div class="form-outline flex-fill mb-0">
                      <input type="text" id="form3Example1c" class="form-control" placeholder="Your Name" />
&lt;!&ndash;                      <label class="form-label" for="form3Example1c">Your Name</label>&ndash;&gt;
                    </div>
                  </div>

                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-envelope fa-lg me-3 fa-fw"></i>
                    <div class="form-outline flex-fill mb-0">
                      <input type="email" id="form3Example3c" class="form-control" placeholder="Email" />
&lt;!&ndash;                      <label class="form-label" for="form3Example3c">Your Email</label>&ndash;&gt;
                    </div>
                  </div>

                 &lt;!&ndash; <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                    <div class="form-outline flex-fill mb-0">
                      <input type="password" id="form3Example4c" class="form-control" placeholder="Password" />
&lt;!&ndash;                      <label class="form-label" for="form3Example4c">Password</label>&ndash;&gt;
                    </div>
                  </div>&ndash;&gt;

                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-key fa-lg me-3 fa-fw"></i>
                    <div class="form-outline flex-fill mb-0">
                      <input type="password" id="form3Example4cd" placeholder="Password" class="form-control" />
&lt;!&ndash;                      <label class="form-label" for="form3Example4cd">Repeat your password</label>&ndash;&gt;
                    </div>
                  </div>

                  <div class="form-check d-flex justify-content-center mb-5">
                    <div class="checkbox p-0 text-left">
                      <input id="checkbox2" type="checkbox" [(ngModel)]="agreeTermsCondtions" [ngModelOptions]="{standalone: true}">
                      <label for="checkbox2" class="f_12">
                        <small>I agree to terms & conditions</small>
                      </label>
                    </div>
                  </div>

                  <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                    <button type="button" class="btn btn-primary btn-lg">Register</button>
                  </div>
                </form>
              </div>
              <div class="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">
                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                     class="img-fluid" alt="Sample image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>-->


<div class="container authentication-main mx-auto padding-0">
  <ng-template #content let-modal>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'FORGOT_PASSWORD'">Forgot Password</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'RESET_PASSWORD'">Reset Password</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'USER_REGISTRATION_OTP'">User Registration
          OTP</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalService.dismissAll('')"
        >
          ×
        </button>
      </div>
      <div class="modal-body" ngbAutoFocus>
        <div class="row" *ngIf="modalType === 'USER_REGISTRATION_OTP'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <form class="form" novalidate [formGroup]="registrationOtpForm">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <fieldset class="form-group">
                    <input class="form-control" type="number" formControlName="otp"
                           placeholder="Enter OTP">
                    <small *ngIf="submittedOtp && registrationOtpForm.get('otp').hasError('required')"
                           class="text-danger">
                      Field is required.
                    </small>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'FORGOT_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="enrollmentVerifyForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <fieldset class="form-group">
                        <label>Enrollment No.</label>
                        <input class="form-control" type="text" formControlName="enrollment_no"
                               placeholder="Enter Enrollment No.">
                        <small
                          *ngIf="submittedEnrollmentNoVerify && enrollmentVerifyForm.get('enrollment_no').hasError('required')"
                          class="text-danger">
                          Field is required.
                        </small>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'RESET_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="updatePasswordForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Verification Code Sent to</label>
                        <input class="form-control" formControlName="contact"/>
                        <small *ngIf="submitted && updatePasswordForm.get('contact').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Password
                          <i (click)="inputTypePassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypePassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypePassword" formControlName="password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Confirm Password
                          <i (click)="inputTypeConfirmPassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypeConfirmPassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypeConfirmPassword"
                               formControlName="confirm_password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('confirm_password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Verification Code</label>
                        <input class="form-control" formControlName="otp" type="number"/>
                        <small *ngIf="submitted && updatePasswordForm.get('otp').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1" *ngIf="modalType === 'USER_REGISTRATION_OTP'">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
            <small>Resend OTP in - </small>
            <small class="text-danger mr-1"
                   *ngIf="timerSixtySeconds > 0">{{timerSixtySeconds}} sec.</small>
            <small class="text-primary cursor-pointer"
                   *ngIf="timerSixtySeconds === 0" (click)="resendOTP()">Resend OTP ?
            </small>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="submitOtp()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'USER_REGISTRATION_OTP'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Submit
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="verifyEnrollmentNo()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'FORGOT_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Verify
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="resetPassword()"
                    [disabled]="submittingPassword" *ngIf="modalType === 'RESET_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingPassword"></i>Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="card card0"> <!---->
    <div class="row d-flex">
      <div class="col-lg-6">
        <div class="card1 pb-5">
          <div class="row">
            <img src="assets/images/logo/logo.png" class="logo">
          </div>
          <div class="row px-3 justify-content-center mt-4 mb-5 border-line">
            <img src="https://i.imgur.com/uNGdWHi.png" class="image">
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-xs-12">
        <form class="theme-form" [formGroup]="loginForm" *ngIf="formChange">
          <div class="card2  px-4 py-5">
            <div class="row mb-4 px-3">
              <!--mb-4 px-3-->
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <!--<div class="card">
                  <div class="card-header">

                  </div>
                </div>-->
                <h4 class="">
                  <strong>B.Voc Subharti</strong><br>
                  <p><strong class="f-15px">Sign In </strong> to your account and Start boost Up your Skills</p>
                </h4>
              </div>
              <!---->
              <br>
            </div>
            <div class="row px-3">
              <label class="mb-1"><h6 class="mb-0 text-sm">Username</h6></label>
              <input class="mb-4" type="text" type="text" placeholder="Enrollment No. / Email"
                     formControlName="username"/>
            </div>
            <div class="row px-3">
              <label class="mb-1"><h6 class="mb-0 text-sm">Password</h6></label>
              <input type="password" placeholder="*******" formControlName="password"/>
            </div>
            <div class="row px-3 mb-4 mt-4">
              <div class="custom-control custom-checkbox custom-control-inline">
                <input id="chk1" type="checkbox" name="chk" class="custom-control-input">
                <label for="chk1" class="custom-control-label text-sm">Remember me</label>
              </div>
            </div>
            <div class="row mb-3 px-3">
              <button type="submit" class="bg-primary text-center w-100 py-2" style="border: none!important;"
                      (click)="signIn()">Login
              </button>
              <div class="row mt-3">
                <div class="col-6">
                  <span><a class="ml-auto mb-0 text-primary"
                           (click)="openForgotPasswordModal(content)">Forgot Password?</a></span>
                </div>
                <div class="col-6 text-end">
                  <span><a class="ml-auto mb-0 text-primary" (click)="toggle()">Sign Up</a></span>
                </div>
              </div>
            </div>
          </div>
        </form>

        <form class="theme-form" [formGroup]="registrationForm" *ngIf="!formChange">
          <div class="card2  px-4 py-5">
            <div class="row mb-4 px-3">
              <!--mb-4 px-3-->
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <!--   <img src="assets/images/logo/logo.png" class="logo">-->
                <!--<div class="card">
                  <div class="card-header">

                  </div>
                </div>-->
                <h4 class="">
                  <h4 class="text-center"><strong>Register Now</strong></h4>
                  <h6 class="text-center">Enter your details and choose course</h6>
                </h4>
              </div>
              <!---->

              <br>
            </div>
            <div class="row px-3">
              <input class="" type="text" placeholder="Full Name" formControlName="name"/>
              <small *ngIf="submitted && registrationForm.get('name').hasError('required')"
                     class="text-danger">
                Field is required.
              </small>
            </div>
            <div class="row px-3">
              <input type="text" placeholder="Email" formControlName="email"/>
              <small *ngIf="submitted && registrationForm.get('email').hasError('required')"
                     class="text-danger">
                Field is required.
              </small>
              <small
                *ngIf="submitted && registrationForm && registrationForm.controls && registrationForm.controls['email'] && registrationForm.controls['email'].errors && registrationForm.controls['email'].errors.pattern"
                class="text-danger">Invalid email format</small>
            </div>
            <div class="row px-3">
              <input type="number" placeholder="Contact" formControlName="contact"/>
              <small *ngIf="submitted && registrationForm.get('contact').hasError('required')"
                     class="text-danger">
                Field is required.
              </small>
              <small
                *ngIf="submitted && registrationForm && registrationForm.controls && registrationForm.controls['contact'] && registrationForm.controls['contact'].errors && registrationForm.controls['contact'].errors.pattern"
                class="text-danger">Invalid contact format</small>
            </div>
            <div class="row px-3">
              <select formControlName="course_id">
                <option value="">Select Course</option>
                <option [value]="course.id" *ngFor="let course of courses">{{course?.name}}</option>
              </select>
              <small *ngIf="submitted && registrationForm.get('course_id').hasError('required')"
                     class="text-danger">
                Field is required.
              </small>
            </div>
            <div class="row px-3 mb-4 mt-4">
              <div class="custom-control custom-checkbox custom-control-inline">
                <input id="checkbox2" type="checkbox" name="chk" class="custom-control-input"
                       [(ngModel)]="agreeTermsCondtions"
                       [ngModelOptions]="{standalone: true}">
                <label for="chk1" class="custom-control-label text-sm">Remember me</label>
              </div>
            </div>
            <div class="row mb-3 px-3">
              <button type="submit" class="bg-primary text-center w-100 py-2" style="border: none!important;"
                      (click)="register()">Register
              </button>

            </div>
            <div class="row mt-2">
              <div class="col-6">
              </div>
              <div class="col-6 text-end">
                <span><a class="ml-auto mb-0 text-primary" (click)="toggle()">Sign Up</a></span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
